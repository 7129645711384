import { Injectable } from '@angular/core'
import { AngularFireFunctions } from '@angular/fire/functions'

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private cloudFunctions: AngularFireFunctions) {}

  send(sender: string, email: string, subject: string, html: string) {
    const sendEmail = this.cloudFunctions.httpsCallable('sendEmail')
    sendEmail({ sender, email, subject, html }).subscribe((result: any) => {})
  }
}
