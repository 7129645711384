import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule} from '@angular/router';

import {AppComponent} from './app.component';
import {NgxAuthFirebaseUIModule} from 'ngx-auth-firebaseui';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/home/home.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AngularFireDatabaseModule} from 'angularfire2/database';
import {UserService} from './services/user.service';
import {AngularFireModule} from '@angular/fire';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {AuthGuardService} from './services/guards/auth-guard.service';
import {MatTableModule} from '@angular/material/table';
import {UserManagementComponent} from './components/user-management/user-management.component';
import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/core/components.module';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {environment} from '../environments/environment';
import {PrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';
import {TemporaryFrontPageComponent} from './components/temporary-front-page/temporary-front-page.component';
import {UserDeleteConfirmationComponent} from './components/user-delete-confirmation/user-delete-confirmation.component';

export const firebaseConfig = environment.firebase;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    UserManagementComponent,
    AdminLayoutComponent,
    PrivacyPolicyComponent,
    TemporaryFrontPageComponent,
    UserDeleteConfirmationComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(firebaseConfig),
    MatToolbarModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'hu',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxAuthFirebaseUIModule.forRoot(firebaseConfig),
    AppRoutingModule,
  ],
  providers: [UserService, AuthGuardService],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
