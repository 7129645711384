import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-temporary-front-page',
  templateUrl: './temporary-front-page.component.html',
  styleUrls: ['./temporary-front-page.component.css'],
})
export class TemporaryFrontPageComponent implements OnInit {
  public googleFromLink = 'https://forms.gle/x9sJpkUKyoA16UvK9'
  public readonly appStoreLink = '#'
  public readonly playStoreLink = '#'

  constructor() {}

  ngOnInit(): void {}
}
